nav
{
	padding: 0 30px;
	background: white;
	display: flex;
	height: 80px;
	box-shadow: rgba(33, 40, 50, 0.15) 2px 0 28px 0;
	justify-content: left;
	align-items: center;
	position: fixed;
	top: 0;
	width: 100%;
}

nav input[type=text]
{
	height: 30px;
	border-radius: 5px;
	border: none;
	width: 200px;
	text-indent: 5px;
	padding: 5px;
	background: rgb(238, 242, 248);
}

nav h3 a
{
	text-decoration: none;
	color: #222;
	margin: 0 30px;
}