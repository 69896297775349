.result
{
	width: 900px;
	margin: 20px;
	padding: 50px;
	border-radius: 8px;
	background: #c7d6ec; /* linear-gradient(to right bottom, #c7d6ec, #ccdffd); */
	box-shadow: rgba(33, 40, 50, 0.15) 0 2px 28px 0;
}

.result h1
{
	font-size: 2.5rem;
	margin: 0;
}

.result h2
{
	font-size: 1.75rem;
	margin: 20px 0;
}

.cards
{
	display: flex;
	flex-wrap: wrap;
}

@media only screen and (min-width: 800px)
{
	.result
	{
		margin-left: 380px;
	}
}