.containerButton
{
	display: inline-block;
	cursor: pointer;
}

.bar1, .bar2, .bar3
{
	width: 25px;
	height: 4px;
	background-color: black;
	margin: 4px 0;
	transition: 0.4s;
}

.change .bar1
{
	transform: rotate(-45deg) translate(-6.5px, 6px);
}

.change .bar2
{
	opacity: 0;
}

.change .bar3
{
	transform: rotate(45deg) translate(-5px, -5px);
}