.card
{
	color: white;
	background: rgb(0, 97, 242);
	min-height: 170px;
	min-width: 250px;
	margin: 10px;
	padding: 0;
	border-radius: 6px;
	box-shadow: rgba(33, 40, 50, 0.15) 0 2px 28px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-decoration: none;
}

.card h2
{
	margin: 40px;
}

.card-footer
{
	background: rgba(33, 40, 50, 0.03);
	border-top-color: rgba(33, 40, 50, 0.125);
	border-top-style: solid;
	border-top-width: 1px;
	width: 100%;
}

.card-footer p
{
	margin: 20px;
	font-size: 0.9rem;
}

@media only screen and (max-width: 800px)
{
	.card
	{
		margin: 15px 0;
		width: 100%;
	}
}