.sidebar
{
	margin: 0;
	padding: 20px;
	background: #ddd;
	box-shadow: rgba(33, 40, 50, 0.15) 0 2px 28px 0;
	position: fixed;
	left: 0;
	height: 100%;
}

.sidebar .buttons
{
	background: rgb(238, 239, 240);
	padding: 5px 0;
	border-radius: 10px;
	box-shadow: rgba(33, 40, 50, 0.15) 2px 0 28px 0;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-grow: 0;
}

.sidebar .buttons button
{
	transition: 200ms;
	color: #222;
	border: none;
	text-align: center;
	font-size: 18px;
	height: 40px;
	border-radius: 8px;
	margin: 0 5px;
	cursor: pointer;
	width: 150px;
	flex-grow: 5;
}

.sidebar .buttons button.active
{
	background: white;
	transition: 200ms;
	box-shadow: rgb(0, 0, 0) 0 0 0 0, rgba(187, 187, 187, 0.11) 0 1px 1px 0, rgba(187, 187, 187, 0.18) 1px 1px 2px -0.6px, rgba(187, 187, 187, 0.26) 1px 3px 4px -1px;
}

.list-link
{
	text-decoration: none;
	color: rgb(33, 40, 50);
	margin: 20px;
	font-size: 16px;
	display: block;
}

.list-link:hover
{
	color: rgb(0, 97, 242);
}

.list-link.active
{
	color: rgb(0, 97, 242);
	font-weight: bold;
}

.sidebar input[type=text]
{
	font-size: 1.1rem;
	background: rgb(238, 239, 240);
	border: none;
	border-radius: 8px;
	box-shadow: rgba(33, 40, 50, 0.15) 2px 0 28px 0;
	padding: 5px 0;
	margin-top: 15px;
	text-indent: 15px;
	height: 40px;
	width: 100%;
}

.list
{
	margin: 20px 0;
	overflow-y: scroll;
	max-height: calc(100vh - 250px);
}

@media only screen and (max-width: 800px)
{
	.sidebar
	{
		width: calc(100% - 40px);
	}
}